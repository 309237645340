import React, { Component, Fragment } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import ruLocale from '@fullcalendar/core/locales/ru';
import { Select, Calendar, Radio, Switch, Checkbox, Spin, Button } from 'antd';
import { any, find, includes, path, pathOr, propEq, sort } from 'ramda';
import moment from 'moment';
import { LeftOutlined, RightOutlined, UnorderedListOutlined, CalendarOutlined } from '@ant-design/icons';
import { asyncConnect, toSuccess } from 'react-async-client';
import { withStateHandlers } from 'recompose';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { getConsultations, getEvents, getProfilePath, getSpeakers, getUser } from '../actions/asyncActions';
import { openEventModal, openConsultationModal, openConsultationViewModal } from '../actions/modalActions';
import { takeEvery } from 'redux-saga/effects';
import { DELETE_EVENT_PARTICIPATE, PUT_EVENT_PARTICIPATE } from '../constants/actionTypes';
import notFound from '../assets/img/notFound.svg';

const StyledRadioButtons = styled(Radio.Group)`
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    .ant-radio-button-wrapper {
        border: none;
        background: #F7F8FC;
        color: #2B3D4F;
        font-weight: 600;
    }
    .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
        background: #2B3D4F;
        border: none;
        color: #fff;
        font-weight: 600;
    }
    .ant-radio-button-wrapper::before {
        background: transparent;
    }
`;

const StyledSelect = styled(Select)`
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    .ant-select-selector {
        background: #F7F8FC !important;
        border: none !important;
        font-weight: 600;
        color: #2B3D4F;
    }
`;

const StyledCheckbox = styled(Checkbox)`
    display: flex;
    margin: 5px 0 0 0!important;
    align-items: flex-start;
    .ant-checkbox {
        top: 2px;
    }
`;

const CalendarWrapper = styled.div`
    background: #F9F8FD;
    border: 1px solid #F9F8FD;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 16px;
    margin-bottom: 24px;
    .ant-picker-calendar > div {
        background: #F9F8FD;
    }
    .ant-picker-panel {
        border-top: 0;
        padding-top: 20px;
    }
    thead th {
        font-weight: 600;
        padding-bottom: 10px !important;
    }
    @media (max-width: 767px) {
        display: none;
    }
`;

const ListItem = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    padding: 25px;
    border-bottom: ${({ last }) => last ? 0 : '1px solid #A7B9CF'};
`;

const ListItemDescription = styled.div`
    display: flex;
    align-items: center;
    @media (max-width: 767px) {
        flex-direction: column;
        align-items: baseline;
    }
`;

const ListItemDescriptionSub = styled.div`
    display: flex;
    align-items: center;
`;

const ListItemDay = styled.div`
    font-size: 36px;
    font-weight: 600;
    color: #2B3D4F;
    @media (max-width: 767px) {
        font-size: 24px;
    }
`;

const ListItemMonthWrapper = styled.div`
    text-align: left;
    margin-left: 25px;
`;

const ListItemMonth = styled.div`
    font-weight: 600;
    color: #2B3D4F;
    font-size: 18px;
`;

const ListItemTime = styled.div`
    color: #A7B9CF;
`;

const ListItemTitle = styled.div`
    font-size: 20px;
    color: #2B3D4F;
    font-weight: 600;
    text-decoration: underline;
    margin-left: 45px;
    cursor: pointer;
    @media (max-width: 767px) {
        font-size: 16px;
        margin-left: 0;
    }
`;

const ListItemIcon = styled.div`
    background: ${({ color }) => color};
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    height: 100%;
    width: 4px;
    min-width: 4px;
`;

const WebinarShow = styled.div`
    .ant-select {
        margin: 16px 0;
    }
`;

const WrapManager = styled.div`
    margin-top: 16px;
    strong {
        font-size: 16px;
    }
    small {
        font-size: 14px;
    }
`;

const NotFound = styled.div`
    text-align: center;
    border-radius: 16px;
    background: #F7F8FC;
    padding: 65px;
    font-size: 20px;
    .future-events-link {
        text-decoration-line: underline;
        color: #F54D2E;
        cursor: pointer;
    }
`;

const CalendarHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
`;

const ContactsWrap = styled.div`
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #828282;
    margin-top: 6px;
`;

const TagWrap = styled.div`
    margin-top: 4px;
    display: inline-flex;
    padding: 6px 24px;
    font-weight: 300;
    font-size: 13px;
    line-height: 11px;
    color: #2B3D4E;
    border-radius: 10px;
    border: 1px solid #2B3D4E;
    border-radius: 4px;
`;

const checkShowWebinars = props => any(propEq('id', 'events'), props.user.modules || []);
const checkShowConsultations = props => any(propEq('id', 'consultations'), props.user.modules || []);

class Webinars extends Component {
    onChangeView = view => {
        this.props.setView(view);
    }

    onChangeType = e => this.props.setType(e.target.value);

    onChangeAttended = e => this.props.setAttended(e.target.value);

    onChangeSpeaker = speaker => this.props.setSpeaker(speaker);

    renderCalendarHeader = ({ value, onChange }) => {
        const prev = () => {
            const selectedDate = value.clone().subtract(1, 'month');

            onChange(selectedDate);
            this.props.setSelectedDate(selectedDate);
        };

        const next = () => {
            const selectedDate = value.clone().add(1, 'month');

            onChange(selectedDate);
            this.props.setSelectedDate(selectedDate);
        };

        return <CalendarHeader>
            <div style={{ fontWeight: 600, textTransform: 'capitalize', color: '#2B3D4F', fontSize: 22 }}>{ value.format('MMMM YYYY') }</div>
            <div>
                <LeftOutlined style={{ marginRight: 16, color: '#2B3D4F' }} onClick={prev} />
                <RightOutlined style={{ color: '#2B3D4F' }} onClick={next} />
            </div>
        </CalendarHeader>;
    }

    renderConsultationListItem = (item, last) => {
        const consultant = path(['_embedded', 'consultant'], item);

        return <ListItem key={`consultation-${item.id}`} last={last}>
            <ListItemIcon color="#2B3D4E" />
            <ListItemDescription>
                <ListItemDescriptionSub>
                    <ListItemDay>{ moment(item.start).format('DD') }</ListItemDay>
                    <ListItemMonthWrapper>
                        <ListItemMonth>{ moment(item.start).format('MMMM') }, { moment(item.start).format('dd') }</ListItemMonth>
                        <ListItemTime>{ moment(item.start).format('HH:mm') }-{ moment(item.end).format('HH:mm') }</ListItemTime>
                    </ListItemMonthWrapper>
                </ListItemDescriptionSub>
                <ListItemTitle onClick={() => this.props.openConsultationViewModal({ item })}>
                    { `Консультация (${consultant.lastName} ${consultant.firstName}${consultant.middleName ? ` ${consultant.middleName}` : ''})` }
                </ListItemTitle>
            </ListItemDescription>
        </ListItem>;
    }

    renderEventListItem = (item, last) => {
        return <ListItem key={`event-${item.id}`} last={last}>
            <ListItemIcon color="#FFD34F" />
            <ListItemDescription>
                <ListItemDescriptionSub>
                    <ListItemDay>{ moment(item.date).format('DD') }</ListItemDay>
                    <ListItemMonthWrapper>
                        <ListItemMonth>{ moment(item.date).format('MMMM') }, { moment(item.date).format('dd') }</ListItemMonth>
                        <ListItemTime>{ moment(item.date).format('HH:mm') }-{ moment(item.date).add(item.duration, 'minutes').format('HH:mm') }</ListItemTime>
                    </ListItemMonthWrapper>
                </ListItemDescriptionSub>
                <ListItemTitle onClick={() => this.props.openEventModal(item)}>
                    { path(['_embedded', 'theme', 'title'], item) }
                </ListItemTitle>
            </ListItemDescription>
        </ListItem>;
    }

    renderList = () => {
        const { getEvents: { data, meta }, getConsultations, showWebinars, showConsultations } = this.props;
        let items = [];

        if (showWebinars) {
            items = items.concat((data.items || []).map(item => ({ ...item, type: 'event' })));
        }

        if (showConsultations) {
            items = items.concat((getConsultations.data.items || []).map(item => ({ ...item, type: 'consultation' })));
        }

        items = sort((a, b) => new Date(b.type === 'event' ? b.date : b.start) - new Date(a.type === 'event' ? a.date : a.start), items);

        return (meta.pending || getConsultations.meta.pending) ? <div style={{ textAlign: 'center' }}><Spin /></div> : <div>
            { items.length ? <div style={{ borderRadius: 16, background: '#F7F8FC', overflow: 'hidden' }}>
                { items.map((item, index) => {
                    switch (item.type) {
                        case 'event':
                            return this.renderEventListItem(item, index === items.length - 1);
                        case 'consultation':
                            return this.renderConsultationListItem(item, index === items.length - 1);
                        default:
                            return null;
                    }
                })}
            </div> : <NotFound>
                <img src={notFound} alt='future-events' />
                <div>событий не найдено в выбранный промежуток времени</div>
                <div class='future-events-link' onClick={() => this.onChangeView('futureEvents')}>показать предстоящие события</div>
            </NotFound> }
        </div>
    }

    setSelectedDate = date => {
        this.props.setSelectedDate(date);
    }

    render() {
        const { getEvents: { data }, speaker, getSpeakers, showWebinars, selectedDate, getMonthEvents, getMonthConsultations, getProfilePath, showConsultations, openConsultationModal, getConsultations } = this.props;
        const themes = pathOr([], ['statistic', 'events', 'themes', '_embedded', 'available'], getProfilePath.data);
        const speakers = pathOr([], ['data', 'items'], getSpeakers);
        const events = showWebinars ? (data.items || []).map(item => ({
            id: item.id,
            title: path(['_embedded', 'theme', 'title'], item),
            start: moment(item.date).format('YYYY-MM-DDTHH:mm:ss'),
            end: moment(item.date).add(item.duration, 'minutes').format('YYYY-MM-DDTHH:mm:ss'),
            allDay: false,
            classNames: ['event']
        })) : [];
        const consultations = showConsultations ? (getConsultations.data.items || []).map(item => {
            const consultant = path(['_embedded', 'consultant'], item);

            return {
                id: item.id,
                title: `Консультация (${consultant.lastName} ${consultant.firstName}${consultant.middleName ? ` ${consultant.middleName}` : ''})`,
                start: moment(item.start).format('YYYY-MM-DDTHH:mm:ss'),
                end: moment(item.end).format('YYYY-MM-DDTHH:mm:ss'),
                allDay: false,
                classNames: ['consultation']
            };
        }) : [];
        const calendarEvents = showWebinars ? (getMonthEvents.data.items || []) : [];
        const calendarConsultations = showConsultations ? (getMonthConsultations.data.items || []) : [];
        const consultant = path(['_embedded', 'consultant'], getProfilePath.data);

        return <div className="calendar-wrap">
            <div className="calendar-wrap-sider">
                <CalendarWrapper>
                    <Calendar
                        fullscreen={false}
                        value={selectedDate}
                        onSelect={this.setSelectedDate}
                        headerRender={this.renderCalendarHeader}
                        dateFullCellRender={date => {
                            const isSelectedDate = selectedDate.isSame(date, 'date');

                            return <div style={{
                                textAlign: 'center',
                                borderRadius: '50%',
                                background: isSelectedDate ? '#2B3D4E' : 'transparent',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                width: 40,
                                height: 40,
                                justifyContent: 'center'
                            }}>
                                <div style={{ color: selectedDate.isSame(date, 'month') ? (isSelectedDate ? '#fff' : '#2B3D4F') : '#ccc' }}>{ date.get('date') }</div>
                                <div style={{ height: 6, display: 'flex' }}>
                                    { any(item => moment(item.date).isSame(date, 'date'), calendarEvents) && <div style={{ marginLeft: 2, marginRight: 2, width: 6, height: 6, borderRadius: '50%', background: isSelectedDate ? '#fff' : '#FFD34F' }} />}
                                    { any(item => moment(item.start).isSame(date, 'date'), calendarConsultations) && <div style={{ marginLeft: 2, marginRight: 2, width: 6, height: 6, borderRadius: '50%', background: isSelectedDate ? '#fff' : '#2B3D4E' }} />}
                                </div>
                            </div>;
                        }} />
                </CalendarWrapper>
                <div>
                { checkShowConsultations(this.props) && consultant &&
                    <Fragment>
                        <div style={{ marginTop: 24, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div style={{ fontWeight: 600, fontSize: 18 }}>
                                <div style={{ display: 'inline-block', width: 8, height: 8, borderRadius: '50%', background: '#2B3D4E', marginBottom: 2 }} /> Консультации
                            </div>
                            <Switch checked={showConsultations} onChange={this.props.setShowConsultations} />
                        </div>
                        { showConsultations &&
                            <WrapManager>
                                <div>
                                    <div><strong>{ consultant.lastName || '' } { consultant.firstName || '' } { consultant.middleName || '' }</strong></div>
                                    <div><TagWrap>персональный консультант</TagWrap></div>
                                    <ContactsWrap>
                                        { consultant.phone && <div>{ consultant.phone }</div>}
                                        <div>{ consultant.email }</div>
                                    </ContactsWrap>
                                </div>
                                { (pathOr(0, ['statistic', 'consultations', 'availableConsultations'], getProfilePath.data) - pathOr(0, ['statistic', 'consultations', 'attendedConsultations'], getProfilePath.data)) > 0 &&
                                    !includes(getProfilePath.data.status, ['employed_before_program', 'employed_in_program', 'finished']) &&
                                    <Button style={{ marginTop: 16 }} type='primary' onClick={() => openConsultationModal({
                                        consultants: path(['_embedded', 'company', '_embedded', 'consultants'], getProfilePath.data),
                                        participant: getProfilePath.data,
                                        onClose: () => {
                                            this.props.getConsultations.refresh();
                                            this.props.getMonthConsultations.refresh();
                                        },
                                        consultant: consultant
                                    })}>
                                        Записаться
                                    </Button>
                                }
                            </WrapManager>
                        }
                    </Fragment>
                }
                { checkShowWebinars(this.props) &&
                    <Fragment>
                        <div style={{ marginTop: 24, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div style={{ fontWeight: 600, fontSize: 18 }}>
                                <div style={{ display: 'inline-block', width: 8, height: 8, borderRadius: '50%', background: '#FFD34F', marginBottom: 2 }} /> Вебинары/семинары
                            </div>
                            <Switch checked={showWebinars} onChange={this.props.setShowWebinars} />
                        </div>
                        { showWebinars &&
                        <WebinarShow>
                            <StyledSelect style={{ width: '100%' }} placeholder='Спикер' value={speaker || undefined} onChange={this.onChangeSpeaker} allowClear>
                                { speakers.map(speaker =>
                                    <Select.Option value={speaker.id} key={speaker.id}>{ speaker.lastName } { speaker.firstName } { speaker.middleName || '' }</Select.Option>
                                )}
                            </StyledSelect>
                            <Checkbox.Group value={this.props.themes || []} onChange={this.props.setThemes}>
                                { themes.map(theme =>
                                    <StyledCheckbox key={`theme-${theme.id}`} value={theme.id}>{ theme.title }</StyledCheckbox>
                                )}
                            </Checkbox.Group>
                        </WebinarShow>
                        }
                    </Fragment>
                }
                </div>
            </div>
            <div className="calendar-wrap-body">
                <div className="calendar-wrap-head">
                    <StyledSelect value={this.props.view} onSelect={this.onChangeView} style={{ width: 220 }}>
                        <Select.Option value='timeGridDay'>День</Select.Option>
                        <Select.Option value='timeGridWeek'>Неделя</Select.Option>
                        <Select.Option value='dayGridMonth'>Месяц</Select.Option>
                        <Select.Option value='futureEvents'>Предстоящие события</Select.Option>
                    </StyledSelect>
                    <StyledRadioButtons value={this.props.type} onChange={this.onChangeType}>
                        <Radio.Button value='calendar'><span className="hide-xs">Календарь</span><span className="hide-md"><CalendarOutlined /></span></Radio.Button>
                        <Radio.Button value='list'><span className="hide-xs">Список</span><span className="hide-md"><UnorderedListOutlined /></span></Radio.Button>
                    </StyledRadioButtons>
                    <StyledRadioButtons value={this.props.attended} onChange={this.onChangeAttended}>
                        <Radio.Button value={null}>Все</Radio.Button>
                        <Radio.Button value={true}>Мои</Radio.Button>
                    </StyledRadioButtons>
                </div>
                <div className="calendar-body">
                    { (this.props.type === 'calendar' && this.props.view !== 'futureEvents') ?
                        <FullCalendar
                            initialDate={selectedDate.format('YYYY-MM-DD')}
                            plugins={[ dayGridPlugin, timeGridPlugin ]}
                            key={`${this.props.view}-${this.props.minDate.toString()}-${this.props.maxDate.toString()}`}
                            initialView={this.props.view}
                            locales={[ruLocale]}
                            locale='ru'
                            scrollTime='00:00'
                            events={[
                                ...events,
                                ...consultations
                            ]}
                            headerToolbar={false}
                            allDaySlot={false}
                            slotLabelFormat={{
                                hour: 'numeric',
                                minute: '2-digit',
                                omitZeroMinute: false,
                                meridiem: 'short'
                            }}
                            eventTimeFormat={{
                                hour: 'numeric',
                                minute: '2-digit',
                                meridiem: false
                            }}
                            displayEventTime={false}
                            eventClick={info => {
                                const className = info.event.classNames[0];

                                switch(className) {
                                    case 'event':
                                        this.props.openEventModal(find(propEq('id', info.event.id), data.items || []));
                                        return;
                                    case 'consultation':
                                        this.props.openConsultationViewModal({
                                            item: find(propEq('id', info.event.id), getConsultations.data.items || []),
                                            onCloseCancel: () => {
                                                this.props.getConsultations.refresh();
                                                this.props.getMonthConsultations.refresh();
                                            },
                                        });
                                        return;
                                    default:
                                        return;
                                }
                            }}
                        /> : this.renderList()
                    }
                </div>
            </div>
        </div>;
    }
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default connect(stateToProps)(withStateHandlers({
    selectedDate: moment(),
    view: 'dayGridMonth',
    type: 'calendar',
    attended: null,
    showWebinars: true,
    speaker: null,
    themes: null,
    minDate: moment().startOf('month').toDate(),
    maxDate: moment().endOf('month').toDate(),
    showConsultations: true
}, {
    setSpeaker: () => speaker => ({ speaker }),
    setView: ({ selectedDate, type }) => view => ({
        view,
        type: view === 'futureEvents' ? 'list' : type,
        minDate: view === 'futureEvents' ? moment().toDate() : view === 'dayGridMonth' ? selectedDate.clone().startOf('month').toDate() : view === 'timeGridWeek' ? selectedDate.clone().startOf('week').toDate() : selectedDate.clone().startOf('day'),
        maxDate: view === 'futureEvents' ? null : view === 'dayGridMonth' ? selectedDate.clone().endOf('month').toDate() : view === 'timeGridWeek' ? selectedDate.clone().endOf('week').toDate() : selectedDate.clone().endOf('day')
    }),
    setThemes: () => themes => ({ themes }),
    setShowWebinars: () => showWebinars => ({ showWebinars, themes: showWebinars ? null : [] }),
    setSelectedDate: ({ view }) => selectedDate => ({
        selectedDate,
        view: view === 'futureEvents' ? 'dayGridMonth' : view,
        minDate: (view === 'dayGridMonth' || view === 'futureEvents') ? selectedDate.clone().startOf('month').toDate() : view === 'timeGridWeek' ? selectedDate.clone().startOf('week').toDate() : selectedDate.clone().startOf('day'),
        maxDate: (view === 'dayGridMonth' || view === 'futureEvents') ? selectedDate.clone().endOf('month').toDate() : view === 'timeGridWeek' ? selectedDate.clone().endOf('week').toDate() : selectedDate.clone().endOf('day')
    }),
    setType: () => type => ({ type }),
    setAttended: () => attended => ({ attended }),
    setShowConsultations: () => showConsultations => ({ showConsultations })
})(asyncConnect(props => ({
    getMonthEvents: getEvents
        .withParams(() => ({ type: 'month' }))
        .withPayload(({ themes, selectedDate, speaker, attended }) => ({
            limit: 0,
            q: {
                themes,
                minDate: selectedDate.clone().startOf('month').toDate(),
                maxDate: selectedDate.clone().endOf('month').toDate(),
                speakers: speaker ? [speaker] : null,
                attended
            }
        }))
        .withSaga(function* (getProps) {
            yield takeEvery([toSuccess(PUT_EVENT_PARTICIPATE), toSuccess(DELETE_EVENT_PARTICIPATE)], () => {
                getProps().getMonthEvents.refresh();
            });
        })
        .withOptions({ dispatchOnMount: checkShowWebinars(props), dispatchOnUpdate: checkShowWebinars(props), resetOnUnmount: true }),
    getMonthConsultations: getConsultations
        .withParams(() => ({ type: 'month' }))
        .withPayload(({ selectedDate }) => ({
            limit: 0,
            q: {
                minDate: selectedDate.clone().startOf('month').toDate(),
                maxDate: selectedDate.clone().endOf('month').toDate()
            }
        }))
        .withOptions({ dispatchOnMount: checkShowConsultations(props), dispatchOnUpdate: checkShowConsultations(props), resetOnUnmount: true }),
    getEvents: getEvents
        .withPayload(({ themes, minDate, maxDate, speaker, attended }) => ({
            limit: 0,
            q: {
                themes,
                minDate,
                maxDate,
                speakers: speaker ? [speaker] : null,
                attended
            }
        }))
        .withSaga(function* (getProps) {
            yield takeEvery([toSuccess(PUT_EVENT_PARTICIPATE), toSuccess(DELETE_EVENT_PARTICIPATE)], () => {
                getProps().getEvents.refresh();
            });
        })
        .withOptions({ dispatchOnMount: checkShowWebinars(props), dispatchOnUpdate: checkShowWebinars(props), resetOnUnmount: true }),
    getConsultations: getConsultations
        .withPayload(({ minDate, maxDate }) => ({
            limit: 0,
            q: {
                minDate,
                maxDate
            }
        }))
        .withOptions({ dispatchOnMount: checkShowConsultations(props), dispatchOnUpdate: checkShowConsultations(props), resetOnUnmount: true }),
    getSpeakers: getSpeakers
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true }),
    getProfilePath: getProfilePath
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true })
}), null, { openEventModal, openConsultationModal, openConsultationViewModal })(Webinars)));
